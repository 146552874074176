import React, { useEffect, useState, useContext } from 'react';
import "../styles/Footer.css";
import { ReactComponent as Footernav } from "../assets/svg/footer/FooterNav.svg";
import PucesComment from "../assets/svg/footer/pucesComment.svg";
// import PuceRating from "../assets/svg/footer/pucesRating.svg";
// import PopupComment from "./popup/Comment.js";
// import PopupRating from "./popup/Rating.js";
import { useNavigate } from 'react-router-dom';
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import PopupMerci from "./popup/Merci.js";
import { getClientByClient } from './api';

export default function Footer() {

    // const [showRatingPuce, setShowRatingPuce] = useState(false);
    // const [showPopupComment, setshoPopupComment] = useState(false);
    // const [showPopupRating, setshoPopupRating] = useState(false);
    const navigate = useNavigate();
    const [showPopupMerci, setshowPopupMerci] = useState(false);
    const [error, setError] = useState(null);  
    const { brand } = useContext(RedirectUrlValue);
    const [onData, setOnData] = useState("");

    const fetchData = async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    if (error) {
        navigate("/");
    }

    // useEffect(() => {
    //     if (window.location.href.split('/').pop() == "borne") {
    //         setShowRatingPuce(true);
    //     } else {
    //         setShowRatingPuce(false);
    //     }
    // }, [window.location.href])

    useEffect(() => {
        fetchData();
    }, []);

    // function handleComment() {
    //     if (showPopupComment == false) {
    //         setshoPopupComment(true)
    //         setshoPopupRating(false)
    //     } else {
    //         setshoPopupComment(false)
    //     }
    // }

    // function handleRating() {
    //     if (showPopupRating == false) {
    //         setshoPopupRating(true)
    //         setshoPopupComment(false)
    //     } else {
    //         setshoPopupRating(false)
    //     }
    // }

    function handleNoPopup() {
        // setshoPopupComment(false);
        // setshoPopupRating(false);
        setshowPopupMerci(false);
    }

    useEffect(() => {
        if(showPopupMerci === true) {
            setTimeout(() => {
                document.location.reload();
            }, 2000)
        }
    }, [showPopupMerci])

    return (
        <footer id='footer'>
            {/* {showPopupComment ? (
                <div className="popups">
                    <div onClick={() => handleNoPopup()} className="shadow"></div>
                    <div className="radiusBorder">
                        <PopupComment setshoPopupMerci={setshoPopupMerci} setshoPopupComment={setshoPopupComment} />
                    </div>
                </div>
            ):(
                <React.Fragment></React.Fragment>
            )} */}

            {/* {showPopupRating ? (
                <div className="popups">
                    <div onClick={() => handleNoPopup()} className="shadow"></div>
                    <div className="radiusBorder">
                        <PopupRating setshoPopupMerci={setshoPopupMerci} setshoPopupRating={setshoPopupRating} />
                    </div>
                </div>
            ):(
                <React.Fragment></React.Fragment>
            )} */}

            {showPopupMerci ? (
                <div className="popups">
                    <div onClick={() => handleNoPopup()} className="shadow"></div>
                    <div className="radiusBorder">
                        <PopupMerci setshowPopupMerci={setshowPopupMerci} />
                    </div>
                </div>
            ):(
                <React.Fragment></React.Fragment>
            )} 
            
            <Footernav fill={onData.couleur} onClick={() => navigate('/mainpage')} className='footerBackground' alt="footer"/>
            <div id="puces">
                {/* {showRatingPuce ? (
                    <img onClick={() => handleRating()} src={PuceRating} className='puce' alt="" />
                ) : (
                    <React.Fragment></React.Fragment>
                )} */}
                {/* <img onClick={() => handleComment()} src={PucesComment} className='puce' alt="" /> */}
            </div>
        </footer>
    );
}