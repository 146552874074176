import React, { useContext, useRef, useState, useEffect } from "react";
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import emailjs from "@emailjs/browser";
import { getClientByClient, getOnLanguage, getModule } from '../api';

export default function ValidationDemandeDeService({ setShowPopupMerci, setShowPopupValidationDemandeDeService, locationInput, selectEtage, selectedAction, mail }) {
    const form = useRef();
    const [onData, setOnData] = useState("");
    const [onLanguage, setOnLanguage] = useState("");
    const [error, setError] = useState(null);    
    const { brand } = useContext(RedirectUrlValue);

    const fetchData = async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
            const languageData = await getOnLanguage(clientData.langue);
            setOnLanguage(languageData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    function handleMerci() {
        setShowPopupValidationDemandeDeService(false);
        setShowPopupMerci(true);
    }

    const sendEmail = (e) => {
        e.preventDefault();
    
        // emailjs.sendForm('service_99e404j', 'template_pp7078k', form.current, 'kuaqzf0uuM1ielbj9')
        emailjs.send("service_9gek74o","template_pp7078k",{
            use_email: mail,
            action: selectedAction,
            lieu: locationInput,
            etage: selectEtage,
            comment: document.getElementById('comment').value,
        }, 'kuaqzf0uuM1ielbj9')
          .then((result) => {
            handleMerci()
          }, (error) => {
              console.log(error.text);
          });
    };

    return (
        <div className="popup popupRating">
            {onLanguage ? (
                <>
                    <h3>{onLanguage.validationDemandeDeService.title}</h3>
                    <p>{onLanguage.validationDemandeDeService.catchPhrase}</p>
                    <form ref={form} onSubmit={sendEmail}>
                        <textarea placeholder={onLanguage.validationDemandeDeService.placeHolder} name="comment" id="comment" cols="30" rows="10" ></textarea>
                        <input style={{backgroundColor: onData.couleur}} className="activeButton" type="submit" value={onLanguage.validationDemandeDeService.send} />
                    </form>
                </>
            ) : ( 
                <p>Loading data...</p>
            )}
        </div>
    );
}