import React, {useContext, useEffect, useState} from "react";
import { ReactComponent as Merci } from "../../assets/svg/merci.svg";
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import { getClientByClient } from '../api';


export default function({setShowPopupMerci}) {

    const [onData, setOnData] = useState("");
    const [error, setError] = useState(null);    
    const { brand } = useContext(RedirectUrlValue);

    const fetchData = async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return(
        <div onClick={() => setShowPopupMerci(false)} className="popup popupMerci">
            <Merci className="merci" fill={onData.couleur}/>
            <p>Votre collaboration nous est précieuse</p>
        </div>
    )
}