import React, { useState, useEffect, useContext } from 'react';
import "../styles/MainPage.css"
import { useNavigate } from 'react-router-dom';
import LogoAnnimation from "./LogoAnnimation";
import { getClientByClient, getOnLanguage, getModule } from './api';
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import DesactiveModule from './popup/DesactiveModule';
import { LanguageValue } from '../context/LanguageValue';

export default function Mainpage() {

    const [showPopupDesacModule, setShowPopupDesacModule] = useState(false);
    const navigate = useNavigate();
    const [onData, setOnData] = useState("");
    const [onLanguage, setOnLanguage] = useState("");
    const [module, setModule] = useState([]);
    const [error, setError] = useState(null);    
    const { brand } = useContext(RedirectUrlValue);
    const { language } = useContext(LanguageValue);

    const fetchData = async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
            if ( clientData.version == "demo") {
                const languageData = await getOnLanguage(language);
                setOnLanguage(languageData);
            } else {
                const languageData = await getOnLanguage(clientData.langue);
                setOnLanguage(languageData);
            }
            const moduleData = await getModule(clientData.client);
            setModule(moduleData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let sliderWidth = ((Object.keys(module).length) * 115 + 40);
        const sliderModule = document.getElementById("sliderModuleId");
        if (sliderWidth < window.innerWidth) {
            sliderModule.classList.add("noSliderModule");
            sliderModule.classList.remove("sliderModule");
        } else {
            sliderModule.classList.add("sliderModule");
            sliderModule.classList.remove("noSliderModule");
        }        
    }, [module])

    const handleClick = (paramRedirect, param, active) => {
        if ( active == false ) {
            setShowPopupDesacModule(true)
        } else {
            navigate('/' + paramRedirect, { state: param });
        }
    };

    if (error) {
        navigate("/");
    }

    function handleNoPopup() {
        setShowPopupDesacModule(false);
    }

    setTimeout(() => {
        
        if ( showPopupDesacModule == true ) {
            setShowPopupDesacModule ( false )
        }

    }, 1000)

    return (
        <div className="mainPage">

            
            {showPopupDesacModule ? (
                <div className="popups">
                    <div onClick={() => handleNoPopup()} className="shadow"></div>
                    <div className="radiusBorder">
                        <DesactiveModule showPopupDesacModule={showPopupDesacModule} />
                    </div>
                </div>
            ):(
                <React.Fragment></React.Fragment>
            )}

            { onData.backgroundimg === false ? (
                <div className='noBckImg'>
                    <LogoAnnimation />
                </div>   
            ) : (
                <div className='bckImg' style={onData.backgroundimg ? { backgroundImage: `url(${onData.bckImgUrl})` } : null}>
                    {onData && <LogoAnnimation opacity={onData.gmLogo}/>}
                </div>
            )}
            <div>
                {onLanguage ? (
                    <h2>{onLanguage.mainPage.workplace}</h2>
                    ) : (
                        <p>Loading data...</p>
                    )
                } 
                <div id='sliderModuleId'>
                    {module.map((item) => (
                        <React.Fragment key={item._id}>
                            <div onClick={() => handleClick(item.type, { module: item._id }, item.active)} className={item.visible && item.active ? 'module' : (item.visible ? 'desac module' : ( item.active ? 'module masquer' : 'module masquer desac'))}>
                                <img src={item.imageUrl} alt="" />
                                <p className="textModule">{item.title}</p>
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
}