import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Gmclassic} from "../assets/svg/logo/GmClassic.svg";
import { LanguageValue } from '../context/LanguageValue';
import FrenchFlag from "../assets/svg/header/Drapeau01.svg";
import DeutchFlag from "../assets/svg/header/Drapeau03.svg";
import { getOnLanguage } from './api';
import "../styles/Home.css";


export default function Home() {

    const { toggleLanguage, language } = useContext(LanguageValue);
    const navigate = useNavigate();
    const [onLanguage, setOnLanguage] = useState("");

    function handleNavigate(urlDestination) {
        navigate("/scanpage/" + urlDestination + "/01/?auth=b8e007b5fd41")
    }
    const fetchData = async () => {
        try {
            const languageData = await getOnLanguage(language);
            setOnLanguage(languageData);
        } catch (error) {
            console.log('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    console.log(onLanguage);

    return (
        <div className="homePage">
            <Gmclassic className="Gmclassic" fill="#1B9A68" alt="Fléche retour" />
            <div className='content'>
                {onLanguage ? (
                    <>
                        {onLanguage && onLanguage.home &&(
                            <>
                                <p>{onLanguage.home.scan}</p>
                                <p>{onLanguage.home.ou}</p>
                                {/* <div onClick={handleScan} className="activeButton">{data[language].home.buttonDemo}</div> */}
                                <div onClick={() => handleNavigate(`greenoffice/${language}/alencon/albert`)} className="activeButton">{onLanguage.home.buttonDemo}</div>
                            </>
                        )}
                    </>
                ) : (
                    <p>Loading data...</p>
                )}
            </div>
            <div className='flags'>
                <img src={FrenchFlag} onClick={() => toggleLanguage("Français")} alt="" />
                <img src={DeutchFlag} onClick={() => toggleLanguage("deutsch")} alt="" />
            </div>
        </div>
    );
}
