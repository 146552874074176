import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getClient } from './api';

export default function Admin() {

    const password = "123";
    const [passwordTrue, setPasswordTrue] = useState(true);
    const navigate = useNavigate();
    const [data, setData] = useState();

    const handlePasswordPrompt = () => {
        const enteredPassword = prompt("Veuillez entrer votre mot de passe :");
    
        if (enteredPassword !== password) {
            setPasswordTrue(true);
        } else {
            setPasswordTrue(false);
            fetchData();
        }
    };

    function handleNavigate(urlDestination) {
        navigate("/scanpage/" + urlDestination + "/01/?auth=b8e007b5fd41")
    }

    const fetchData = async () => {
        try {
            const clientData = await getClient();
            console.log(clientData)
            setData(clientData);
        } catch (error) {
            console.log('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    return(
        <React.Fragment>
            {passwordTrue ? (
                <div onClick={() => handlePasswordPrompt()}>ok</div>
            ) : (
                <div className="page">
                    <p>page Admin</p>
                    {data && data.map((item) => (
                        <p key={item._id} onClick={() => handleNavigate(item.oldUrl)}>{item.client}</p>
                    ))}
                </div>
            )}
        </React.Fragment>
    )
}