import React, { useContext, useEffect, useState } from 'react';
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import { isValidUniqueId } from "./Aes.js";
import axios from 'axios';
import { ReactComponent as Gmclassic} from "../assets/svg/logo/GmClassic.svg";
import "../styles/Home.css"

export default function Scanpage() {

    const {toggleredirectUrl} = useContext(RedirectUrlValue);
    const [isScan, setIsScan] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Ajout d'un nouvel état pour gérer le chargement

    const headers = {
        'Content-Type': 'application/json',
        'apiKey': process.env.REACT_APP_API_SECRET_HASH
    };

    useEffect(() => {
        const client = window.location.href.split('/')[4].toLowerCase();
        updateData(client).then(() => {
            handleScan(window.location.href);
            setIsLoading(false); // On arrête le chargement une fois que les données sont prêtes
        }).catch(async error => {
            console.error(error, "Erreur lors de la mise à jour des données");
            setIsLoading(false); // On arrête également le chargement en cas d'erreur
            try {
                await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/errors`, {
                    error: error.message, 
                    client,
                    time: new Date().toISOString()
                }, { headers } );
                handleScan(window.location.href);
            } catch (loggingError) {
                handleScan(window.location.href);
            }
        });
    }, []);
    
    function updateData(client) {
        return axios.put(`${process.env.REACT_APP_API_BASE_URL}/api/clientinfo/${client}/increment`, {}, { headers });
    }

    function handleScan(scanData) {
        const qrResult = scanData.toLowerCase();
        if (qrResult.includes("greenme.info") || qrResult.includes("goserveurtest.fr") || qrResult.includes("localhost")) {
            const urlSplit = qrResult.split("/");
            const isValid = isValidUniqueId(urlSplit[urlSplit.length - 1].split("=")[1]);
            const urlArray = urlSplit.slice(urlSplit.length - 6).slice(0, 5);
            toggleredirectUrl(urlArray[0]);
            if (isValid) {
                setIsScan(true);
            } else {
                window.location.href = '/'
            }
        }
    }

    useEffect(() => {
        if (isScan) {
            window.location.href = "/mainpage";
        }
    }, [isScan]);

    return (    
        <div className="load">
            {/* On n'affiche le logo que si les données sont en cours de chargement */}
            {isLoading && <Gmclassic className="Gmclassic" fill="#1B9A68" alt="Fléche retour" />}
        </div>
    )
}