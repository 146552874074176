import React, { useState, useContext, useEffect, useRef } from 'react';
import "../styles/Header.css"
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import { useNavigate } from 'react-router-dom';
import LogoDemo from '../assets/svg/logo/GreenofficeDemo.svg';
import ContactMail from "../assets/svg/header/ContactMail.svg";
import { getClientByClient, getOnLanguage } from './api';

export default function Header() {
    const [logo, setLogo] = useState(true);
    const navigate = useNavigate();
    const [showFixHeader, setShowFixHeader] = useState(false);
    const retourButtonRef = useRef(null);
    const [error, setError] = useState(null);  
    
    const [onData, setOnData] = useState("");
    const [onLanguage, setOnLanguage] = useState("");
    const { brand } = useContext(RedirectUrlValue);

    const fetchData = async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
            const languageData = await getOnLanguage(clientData.langue);
            setOnLanguage(languageData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    if (error) {
        navigate("/");
    }

    useEffect(() => {
        fetchData();
    }, []);

    /* chargement du logo et de la couleur de fond */
    useEffect(() => {
        if (onData.version === "Demo") {
            setShowFixHeader(true);
        }
        if (onData.backgroundColorHeader === true) {
            let headerClass = document.getElementById("header")
            headerClass.style.backgroundColor = onData.couleur
            headerClass.style.color = "white"
        } else {
            let headerClass = document.getElementById("header")
            headerClass.style.borderBottom = `3px solid ${onData.couleur}`;
            headerClass.style.color = onData.couleur;
        }
    }, [onData]);
    

    return (
        <React.Fragment>
            {showFixHeader ? (
                <div onClick={() => navigate('/democontact')} className="fixHeader">
                    <div className='containerLogo rightPartElement'>
                        <img className='logoDemo' src={LogoDemo} alt="Logo page demo" />
                    </div>
                    {onLanguage ? (
                        <p className='rightPartElement'>{onLanguage.header.link}</p>
                    ) : (
                        <p>Loading data...</p>
                    )}
                    <div className='rightPart rightPartElement'>
                        <img className='imgContact' src={ContactMail} alt="" />
                    </div>
                </div>
            ) : null}

            <header onClick={() => navigate('/mainpage')} id='header'>
                {onLanguage ? (
                    <p onClick={() => navigate(-1)} className='retourButton' id='retourButton' ref={retourButtonRef}>&lt; {onLanguage.header.retour}</p>
                ) : (
                    <p>Loading data...</p>
                )}
                {logo ? (
                    <img onClick={() => navigate(-1)} className='brandLogo' src={onData.logoUrl} alt="" />
                ) : null}
            </header>
        </React.Fragment>
    );
}