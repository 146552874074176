import React, { useState, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RedirectUrlValue } from '../context/RedirectUrlValue.js';
import { LanguageValue } from '../context/LanguageValue';
import { DataLanguage } from '../context/DataLanguage';
import Separateur from "../assets/svg/separateur.svg";
import "../styles/Borne.css"
import { getClientByClient } from './api';

export default function Borne(props) {
    
    const navigate = useNavigate();
    const { state } = useLocation();
    const [borne, setBorne] = useState('');
    const { language } = useContext(LanguageValue);
    const { data } = useContext(DataLanguage);
    const [onData, setOnData] = useState("");
    const [error, setError] = useState(null);    
    const { brand } = useContext(RedirectUrlValue);

    const fetchData = async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);;

    // console.log(state.detail.descriptionFalse)

    return (
        <div className='bornePage page'>
            <div className='titlePage'>
                <img src={state.svgIcon} alt="" />
            <div className='centerTexte'>
                    <h3>{state.detail.value}</h3>
                </div>
            </div>
            <img className='borneImg' src={state.detail.image} alt="Borne de trie" />
            <div className='descriptionElement'>
                {state.detail.descriptionTrue ? (     
                <div className='trueElement'>
                    <h4 style={{color: onData.couleur}}>{state.titleTrue}</h4>
                    <ul>    
                        {state.detail.descriptionTrue.map((item) => (
                            <li key={item.trim()}>{item.trim()}</li>
                        ))}
                    </ul>
                </div>
                ) : (
                    <></>
                )}
                {state.detail.descriptionFalse.length > 0 ? (
                    <>
                        <img className='separateurTextBorne' src={Separateur} alt="" />
                        <div className="falseElement">
                            <h4>{state.titleFalse}</h4>
                            <ul>    
                                {state.detail.descriptionFalse.map((item) => (
                                    <li key={item.trim()}>{item.trim()}</li>
                                ))}
                            </ul>
                        </div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            {data ? (
                <div style={{ backgroundColor: `${onData.couleur}` }} onClick={() => navigate(-1)} className="activeButton">
                {data[language] && data[language].borne && data[language].header.retour}
            </div>
            
            ) : (
                <p>Loading Data...</p>
            )}
            
        </div>
    );
}