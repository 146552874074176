import axios from 'axios';

const headers = {
    'Content-Type': 'application/json',
    'apiKey': process.env.REACT_APP_API_SECRET_HASH
};

export const getClientByClient = async (clientId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/clientinfo/client/${clientId}`, { headers });
        return response.data[0];
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getClient = async () => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/clientinfo`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getOnLanguage = async (lang) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/langue/`, { headers });
        return response.data[0][lang];
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getModule = async (clientId) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/module/client/${clientId}`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};

export const getModuleById = async (Id) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/api/module/${Id}`, { headers });
        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};