import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as GmClassic } from "../assets/svg/logo/GmClassic.svg"
import "../styles/ErrorData.css"

export default function Errordata() {
    
    const { state } = useLocation();

    return (
        <div className="errorDataPage">
            <GmClassic className="" fill="#1B9A68" />
            <h2>Une erreur est survenue</h2>
            <p>Erreur: {state}</p>
            <p>Veuillez contacter un administrateur</p>
            <Link to={"/"}>
                Retour à l'accueil
            </Link>
        </div>
    );
}
