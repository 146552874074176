import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function QueDevientMonDechet() {

    const navigate = useNavigate();

    useEffect(() => {
        navigate("/mainpage");
    }, [])
    
    return(
        <p>PDF non founi pour le moment</p>
    )
}