import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import "../../styles/TypeDeDechet.css"
import { getClientByClient, getModuleById } from '../api';

export default function TypeDeDechet(props) {

    const navigate = useNavigate();
    const { state } = useLocation();
    const [showText, setShowText] = useState(true)  
    const [onData, setOnData] = useState("");
    const [module, setModule] = useState({});
    const [error, setError] = useState(null);    
    const { brand } = useContext(RedirectUrlValue);

    
    const fetchData = async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
            const moduleData = await getModuleById(state.module);
            setModule(moduleData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    /* redirection borne page */
    function handleClick(param, svg) {
        navigate("/borne", {
            state: {
                svgIcon: svg,
                detail: module.bornePage.detailBorne.filter(borne => borne.value === param)[0],
            titleFalse: module.bornePage.titleFalse,
            titleTrue: module.bornePage.titleTrue
        }});
    }
    

    // useEffect(() => {
    //     console.log(module.details)
    // }, [module])

    const classNames = ["module-left", "module-center", "module-right"];

    return (
        <div className="typeDeDechetPage page">
            <div className="titlePage">
                <div className="centerTexte">
                    <h3>{module.title}</h3>
                </div>
            </div>
            <div>
                {module.details && module.details.reduce((acc, curr, index) => {
                    if (index % 3 === 0) acc.push([]);
                    acc[acc.length - 1].push(curr);
                    return acc;
                }, []).map((iconGroup, groupIndex) => (
                    <div key={groupIndex} className={iconGroup.length === 4 ? ("containerModule4 containerModule") : ("containerModulePlus containerModule")}>
                        {iconGroup.map((icon, iconIndex) => (
                            <div onClick={() => handleClick(icon.destination, icon.imageUrl)} className={classNames[iconIndex]} key={icon.id}>
                                <img className={"module"} src={icon.imageUrl} alt={icon.value} />
                                {showText ? (
                                    <p style={{ color: onData.couleur }}>{icon.value}</p>
                                ) : (
                                    <React.Fragment></React.Fragment>
                                )}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}