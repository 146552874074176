import React, { useEffect, useState, useContext } from "react";
import { ReactComponent as Gmsmall } from "../../assets/svg/logo/GmSmallBck.svg";
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import { LanguageValue } from '../../context/LanguageValue';
import { DataLanguage } from '../../context/DataLanguage';

export default function() {

    const {color} = useContext(RedirectUrlValue);
    const { language } = useContext(LanguageValue);
    const { data } = useContext(DataLanguage);

    return(
        <div className="popup popupComment">
            {data ? (
                <>
                    <h3>Ce module est désactivé</h3>
                </>
                
            ) : (
                <p>Loading data...</p>
            )}
        </div>
    )
}