import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { LanguageValue } from '../../context/LanguageValue';
import { RedirectUrlValue } from '../../context/RedirectUrlValue.js';
import { DataLanguage } from '../../context/DataLanguage';
import ServiceSvg from  "../../assets/svg/iconsAlert/serviceTitle.svg";
import PopupMerci from "../popup/Merci.js";
import ValidationDemandeDeService from "../popup/ValidationDemandeDeService.js";
import "../../styles/DemandeDeService.css";
import { getClientByClient, getModuleById } from '../api';

export default function DemandeDeService() {
    
    const { state } = useLocation();
    const [icons, setIcons] = useState([]);
    const [selectedAction, setSelectedAction] = useState([]);
    const [showActiveButton, setShowActiveButton] = useState(true);
    const [showPopupMerci, setShowPopupMerci] = useState(false);
    const [showPopupValidationDemandeDeService, setShowPopupValidationDemandeDeService] = useState(false);
    const [selectEtage, setSelectEtage] = useState([]);
    const [selectLocalisation, setSelectLocalisation] = useState("");
    const { language } = useContext(LanguageValue);
    const { data } = useContext(DataLanguage);  
    const [onData, setOnData] = useState("");
    const [module, setModule] = useState({});
    const [error, setError] = useState(null);    
    const { brand } = useContext(RedirectUrlValue);

    const fetchData = async () => {
        try {
            const clientData = await getClientByClient(brand);
            setOnData(clientData);
            const moduleData = await getModuleById(state.module);
            setModule(moduleData);
        } catch (error) {
            setError('Une erreur s\'est produite lors de la récupération des données.');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

/* Affichage des différents picto */

    useEffect(() => {
        if (module !== null) {
            setSelectEtage(("1 _2 _3 _4").split('_'))

            if (module.localisation !== undefined) {
                setSelectLocalisation(module.localisation);
            }

            if (module.etage !== undefined) {
                setSelectEtage((module.etage));
            }
        }
    }, [module])

/* slélecitonné les actions */
    function handleSelectAction(actionId, actionText) {
        if (selectedAction.includes(actionText)) {
            setSelectedAction(selectedAction.filter(e => e !== actionText));
            const selectedElementText = document.getElementsByClassName(actionId)[0];
            selectedElementText.style.color = "black";

            const selectedElementModule = document.getElementsByClassName("module-" + actionId + "img")[0];
            selectedElementModule.style.background = "var(--grey)";
        } else {
            setSelectedAction([...selectedAction, actionText])

            const selectedElementText = document.getElementsByClassName(actionId)[0];
            selectedElementText.style.color = onData.couleur;

            const selectedElementModule = document.getElementsByClassName("module-" + actionId + "img")[0];
            selectedElementModule.style.background = onData.couleur;
        }
    }

/* activé le boutton */
    function handleActiveButton() {

        var inputValue = document.getElementById("locationInput")?.value;
        var selectValue = document.getElementById("etage-select")?.value;

        if (selectedAction.length && inputValue.length && selectValue !== 'Étage') {
            setShowActiveButton(false)
        } else {
            setShowActiveButton(true)
        }
    }

    useEffect(() => {
        handleActiveButton()
    }, [ selectedAction ])

    function handleValidComment() {
        setShowPopupValidationDemandeDeService(true);
    }

    function handleNoPopup() {
        setShowPopupValidationDemandeDeService(false)
    }
    
    useEffect(() => {
        if(showPopupMerci === true) {
            setTimeout(() => {
                document.location.reload()
            }, 2000)
        }
    }, [showPopupMerci])

    return(
        <>
            {module && module.details ? (

                <div className="demandeDeServicePage page">
                    <div className='titlePage'>
                        <img src={module.imageUrl} alt="" />
                        <div className="centerTexte">
                            {data ? (
                                <h3>{data[language].demandeDeService.title}</h3>
                            ) : (
                                <p>Loading data...</p>
                            )
                            } 
                        </div>
                    </div>
                    <div className={module.details.length == 2 ? ("containerModule2") : ("containerModule")}>
                        
                        <div className="module-text">
                            {module.details.map((icon, index) => (
                                <p onClick={() => handleSelectAction(icon.id, icon.value)} className={icon.id} key={icon.id}>{icon.value}</p>
                            ))}
                        </div>

                        {module.details.map((icon, index) => (
                            <div onClick={() => handleSelectAction(icon.id, icon.value)} className={"module-" + (index + 1)} key={icon.id}>
                                <img className={"module module-" + icon.id + "img"} src={icon.imageUrl} alt={icon.value} />
                            </div>
                        ))}
                    </div>

                    <div>
                        <div  className="locations">
                            {data ? (
                                <input onBlur={() => handleActiveButton()} id="locationInput" className="inputLocation location" placeholder={data[language]?.demandeDeService?.placeHolder} type="text" value={selectLocalisation}  onChange={(e) => setSelectLocalisation(e.target.value)}/>
                            ) : (
                                <input onBlur={() => handleActiveButton()} id="locationInput" className="inputLocation location" placeholder="Loading data" type="text" />
                            )}
                            <select style={{ borderLeft: `2px solid ${onData.couleur}` }} onChange={() => handleActiveButton()} className="selectLocation location" name="etage" id="etage-select" defaultValue="">
                                {data ? (
                                    <option hidden>{data[language].demandeDeService.etage}</option>
                                ) : (
                                    <option hidden>Loading data</option>
                                )}
                                    {selectEtage.map((item, index) => (
                                        <option key={index} value={item.trim()}>{item.trim()}</option>
                                    ))}
                            </select>
                        </div>

                        {data ? (
                            showActiveButton ? (
                                <div style={{ border: `1px solid ${onData.couleur}`, color: onData.couleur }} className="button">{data[language].demandeDeService.send}</div>
                            ) : (
                                <div style={{ backgroundColor: `${onData.couleur}` }} onClick={() => handleValidComment()} className="activeButton">{data[language].demandeDeService.send}</div>
                            )
                        ) : (
                            <p>Loading data ...</p>
                        )}

                        {showPopupValidationDemandeDeService && (
                            <div className="popups">
                                <div onClick={() => handleNoPopup()} className="shadow"></div>
                                <div className="radiusBorder">
                                    <ValidationDemandeDeService 
                                        setShowPopupMerci={setShowPopupMerci}
                                        setShowPopupValidationDemandeDeService={setShowPopupValidationDemandeDeService}
                                        locationInput={document.getElementById("locationInput").value}
                                        selectEtage={document.getElementById("etage-select").value}
                                        selectedAction={selectedAction}
                                        mail={module.mail}
                                    />
                                </div>
                            </div>
                        )}

                        {showPopupMerci && (
                            <div className="popups">
                                <div onClick={() => handleNoPopup()} className="shadow"></div>
                                <div className="radiusBorder">
                                    <PopupMerci setShowPopupMerci={setShowPopupMerci} />
                                </div>
                            </div>
                        )} 
                    </div>
                </div>

            ) : (
                <p>Loading Data ....</p>
            )}
        </>
    )
}